import React from "react";
import Navbar from "../components/navbar";

export default function Services() {
  return (
    <div>
      <Navbar selected="Services" />
    </div>
  );
}
