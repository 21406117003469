import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ResetPassword from "./screens/resetPassword";
import Confirm from "./screens/confirm";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Services from "./screens/services";
import Products from "./screens/products";
import About from "./screens/about";
import Error from "./screens/error";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

  {
    path: "/unified/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/unified/confirmation",
    element: <Confirm />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "*",
    element: <Error />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
