import React from "react";
import "../css/Navbar.css";
export default function Navbar({ selected }) {
  return (
    <div className="heading">
      <div className="left-nav">
        <a href="/">
          <img src={require("../assets/s3.png")} alt="logo" />
        </a>
        <a href="/" className="title">
          Stewart Software Solutions
        </a>
      </div>

      <div className="nav-divider"></div>
      <ul className="nav-options">
        <li className="nav-option">
          <a
            className={
              selected == "Home" ? "nav-text selected" : "nav-text unselected"
            }
            href="/"
          >
            Home
          </a>
        </li>
        <li className="nav-option">
          <a
            className={
              selected == "Services"
                ? "selected nav-text"
                : "unselected nav-text "
            }
            href="/services"
          >
            Services
          </a>
        </li>
        <li className="nav-option">
          <a
            className={
              selected == "Products"
                ? "nav-text selected"
                : "nav-text unselected"
            }
            href="/products"
          >
            Products
          </a>
        </li>
        <li className="nav-option">
          <a
            className={
              selected == "About" ? "nav-text selected" : "nav-text unselected"
            }
            href="/about"
          >
            About
          </a>
        </li>
      </ul>
    </div>
  );
}
