import React from "react";
import Navbar from "../components/navbar";

export default function Products() {
  return (
    <div>
      <Navbar selected="Products" />
    </div>
  );
}
