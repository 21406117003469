import { useEffect } from "react";
import * as api from "./api/userApi";
import Home from "./screens/home";

function App() {
  useEffect(() => {
    // api.getUserByEmail("email@gmail.com").then((data) => {
    //   console.log(data);
    // });
  }, []);

  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
