import React from "react";
import Navbar from "../components/navbar";
import "../css/Home.css";
export default function Home() {
  return (
    <div className="container">
      <Navbar selected="Home" />
      <div className="home-content"></div>
    </div>
  );
}
