import React from "react";
import "../css/Confirm.css";
export default function Confirm() {
  return (
    <div className="confirm-container">
      <div className="confirm-heading">
        <a className="confirm-logo-a" href="/">
          <img
            src={require("../assets/s3.png")}
            alt="logo"
            className="confirm-logo"
          />
        </a>
        <a href="/" className="confirm-title-a">
          <p className="confirm-title">Stewart Software Solutions</p>
        </a>
      </div>
      <div className="confirm-content">
        <h2>Thank you for using our services!</h2>
        <p>
          You have successfully confirmed your email for Unified Mobile. You are
          admitted to the general community. If you applied to a different
          community on signup, you will need to wait to be accepted before you
          can view their community.{" "}
        </p>
      </div>
    </div>
  );
}
