import React, { useState } from "react";

import "../css/ResetPassword.css";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission logic here
  };

  const isValidEmail = () => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const renderEmailError = () => {
    if (!email && submitted) {
      console.log("Email is required");
      return <p className="error">Email is required</p>;
    }

    //check if email is valid

    if (!/\S+@\S+\.\S+/.test(email)) {
      console.log("Email address is invalid");
      return <p className="error">Email address is invalid</p>;
    }

    return null;
  };

  const isValidPassword = () => {
    return password.length >= 8 && /\d/.test(password);
  };

  const renderPasswordError = () => {
    if (!password) {
      console.log("Password is required");
      return <p className="error">Password is required</p>;
    }

    // check if password is at least 8 characters and includes letters and numbers
    if (password.length < 8) {
      console.log("Password must be at least 8 characters");
      return <p className="error">Password must be at least 8 characters</p>;
    }

    if (!/\d/.test(password)) {
      console.log("Password must contain a number");
      return <p className="error">Password must contain a number</p>;
    }

    return null;
  };

  const isValidConfirmPassword = () => {
    return confirmPassword && password === confirmPassword;
  };

  const renderConfirmPasswordError = () => {
    if (!confirmPassword) {
      console.log("Confirm password is required");
      return <p className="error">Confirm password is required</p>;
    }

    if (password !== confirmPassword) {
      console.log("Passwords do not match");
      return <p className="error">Passwords do not match</p>;
    }

    return null;
  };

  const allValid = () => {
    return isValidEmail() && isValidPassword() && isValidConfirmPassword();
  };

  const submitForm = () => {
    setSubmitted(true);
    if (email) {
      // handle form submission logic here
    }
  };

  return (
    <div className="rp-main-container">
      <div
        className={
          allValid()
            ? "reset-password-container-success"
            : "reset-password-container-error"
        }
      >
        <form onSubmit={handleSubmit}>
          <h2 className="title">Reset Password</h2>
          <div className="form-group">
            <label htmlFor="email" className="label">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className={isValidEmail() ? "input-success" : "input-error"}
            />
            {renderEmailError()}
          </div>
          <div className="form-group">
            <label htmlFor="password" className="label">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              className={isValidPassword() ? "input-success" : "input-error"}
            />
            {renderPasswordError()}
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword" className="label">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              className={
                isValidConfirmPassword() ? "input-success" : "input-error"
              }
            />
            {renderConfirmPasswordError()}
          </div>
          <button type="submit" className="submit-button" onClick={submitForm}>
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
}
