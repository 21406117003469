import React from "react";
import Navbar from "../components/navbar";

export default function About() {
  return (
    <div>
      <Navbar selected="About" />
    </div>
  );
}
