import React from "react";
import "../css/Error.css";
export default function Error() {
  return (
    <div className="error-container">
      <p className="error-title">404</p>
      <p className="error-subtitle">This page does not exist</p>
      <a className="error-button" href="/">
        Go Back Home
      </a>
    </div>
  );
}
